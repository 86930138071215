import React, { useEffect, useRef, useState } from "react";
import Quagga from 'quagga';
import './ScannerFullScreen.css';

function ScannerFullScreen({ isOpen, onClose, onDetected }) {
    const scannerRef = useRef(null);
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState(null);

    const getCameras = () => {
        Quagga.CameraAccess.enumerateVideoDevices()
            .then(function (devices) {
                const deviceList = devices.map(device => ({
                    deviceId: device.deviceId,
                    label: device.label || `Camera ${device.deviceId}`
                }));
                setCameras(deviceList);
                const savedCamera = localStorage.getItem('selectedCamera');
                if (savedCamera) {
                    setSelectedCamera(savedCamera);
                } else {
                    setSelectedCamera(deviceList[0]?.deviceId || null); // Seleciona a primeira câmera por padrão
                }
            })
            .catch(err => {
                console.error("Erro ao listar dispositivos:", err);
            });
    };

    const handleCameraChange = (e) => {
        const newCamera = e.target.value;
        setSelectedCamera(newCamera);
        localStorage.setItem('selectedCamera', newCamera); // Salva a câmera selecionada no localStorage
    };

    useEffect(() => {
        if (isOpen && scannerRef.current) {
            const config = {
                inputStream: {
                    name: "Live",
                    type: "LiveStream",
                    target: scannerRef.current, // O elemento onde o vídeo será exibido
                    constraints: {
                        deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
                        facingMode: "environment",
                        aspectRatio: { min: 1, max: 2 },
                        width: { min: 1280 },
                        height: { min: 720 }
                    }
                },
                decoder: {
                    readers: ["ean_reader"] // Leitores de código de barras suportados
                }
            };

            Quagga.init(config, (err) => {
                if (err) {
                    console.log("Erro ao iniciar o Quagga:", err);
                    return;
                }
                Quagga.start();
            });

            Quagga.onDetected((result) => {
                if (result && result.codeResult) {
                    onDetected(result.codeResult.code);
                }
            });

            getCameras();
        }

        return () => {
            if (isOpen) {
                Quagga.stop();
            }
        };
    }, [isOpen, selectedCamera, onDetected]);

    return (
        <div>
            {isOpen && (
                <div>
                    <div ref={scannerRef} className="ScannerFullScreen" onClick={onClose}></div>
                    <div className="btnControls">
                        <select
                            value={selectedCamera || ""}
                            onChange={handleCameraChange}
                        >
                            {cameras.map((cam) => (
                                <option key={cam.deviceId} value={cam.deviceId}>
                                    {cam.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ScannerFullScreen;
