import React from 'react';

function Text({id, value, onChange, placeholder, label}) {
    return (
        <>

            <label>{label}</label>
            <input
                id={id}
                type="text"
                value={value}
                onChange={onChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={placeholder}
            />
        </>
    );
}

export default Text;