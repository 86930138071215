import React, { useEffect, useState } from 'react';
import './Home.css';
import Button from './Button';
import { useNavigate } from 'react-router-dom';


function Home() {

    const navigate  = useNavigate();

    const listarCompras = () => {
        navigate('/compras');
    };

    const novaCompra = () => {
        navigate('nova-compra')
    }

    const novoMercado = () => {
        navigate('/novo-mercado')
    }

    return (        
        <div className="overflow-x-auto bg-gray-100 p-4 min-h-screen">

            <button 
                onClick={novaCompra}
                className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-2"
            >Nova Compra</button>
            <button 
                onClick={listarCompras}
                className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-2"
            >Listar Compras</button>
            <Button onClick={novoMercado}>Cadastrar Mercado</Button>
            
        </div>
    )
}

export default Home;