import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {

    const navigate  = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');


    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password
                })
            });
            console.log('ok')

            if (!response.ok) {
                throw new Error('Credenciais inválidas.');
            }

            const data = await response.json();
            const resultToken = data.token;

            // Armazene o token no localStorage
            const ttl = 3600000 // 60 minutos
            const token = {
                value: resultToken,
                expiry: (new Date()).getTime() + ttl
            }
            const res = localStorage.setItem('token', JSON.stringify(token));
            console.log(res);

            setError(null)
            //setAuthToken(token); // Atualize o estado global
            navigate('/')
            console.log('/')

        } catch (err) {
            console.log(err)
            setError('Credenciais inválidas. Tente novamente.');
        }
    };

    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">

            <div>
                <img src="/android-chrome-512x512.png" className='logo-home mx-auto'/>
                <div className='mx-auto p-4 text-center font-bold text-gray-600 text-3xl'>Meu Mercado</div>
            </div>
            <div className="w-full max-w-xs">
                
                <form onSubmit={handleLogin} className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Usuário
                        </label>
                        <input
                            id="username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Digite seu usuário"
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Senha
                        </label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Digite sua senha"
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                        >
                            Entrar
                        </button>
                    </div>

                    <div className='mt-2'>{error}</div>
                </form>
            </div>
        </div>
    );
}

export default Login;
