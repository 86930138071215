import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
    const checkAuth = () => {
        const tokenStr = localStorage.getItem('token');
        
        if (!tokenStr) return false;
        
        const token = JSON.parse(tokenStr);
        const now = new Date();

        // Verificar se o token expirou
        return now.getTime() < token.expiry;
    };

    return checkAuth() ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
