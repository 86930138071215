import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ListaCompras.css';
import { fetchGet } from '../Api';
import Navbar from './Navbar';
function ListaCompras() {


    const [data,setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect ( () => {
        const fetchData = async () => {
            try {

                const response = await fetchGet('/purchases');
                console.log(response)
                setData(response.body);

            } catch (error)  {

            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [])

    const openPurchase = (id) => {
        navigate(`/compra/${id}`)
    }

    const goToHome = () => {
        navigate('/')
    }

    if (loading) return <p>carregando...</p>;
    if (error) return <p>{`Erro: ${error}`}</p>

    return (
        
        <>
        <div className='className="overflow-x-auto bg-gray-100 p-4 min-h-screen'>
            <h1 className='text-gray-600 font-bold text-2xl mb-4 text-center'>Compras realizadas</h1>

            {data ? (
                data.map((purchase) => (
                    <div className="bg-white p-2 text-sm mb-3 rounded shadow text-gray-600" key={purchase.id} onClick={() => {openPurchase(purchase.id)}}>
                        <strong><h2>{purchase.name}</h2></strong>
                        <p>Data da Compra: {new Date(purchase.created_at).toLocaleDateString()}</p>
                    </div>
                ))
            ) : (
                <p>Nenhuma compra encontrada.</p>
            )}

            <button 
                onClick={goToHome}
                className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            >Voltar</button>
        </div>
        </>
    );
}

export default ListaCompras;