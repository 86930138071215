import React from 'react';

function Button({children, onClick, type}) {

    return (
        <>
        <button 
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-2"
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
        </>
    )
}

export default Button