import React, { useState } from 'react';
import Text from './Text';
import Button from './Button';
import { fetchPost } from '../Api';
import { Navigate, useNavigate } from 'react-router-dom';

function NovoMercardo() {

    const navigate = useNavigate()
    const [value, setValue] = useState()
    
    const handleForm = async(e) => {
        e.preventDefault();
        
        const market = {name: value}
        const res = await fetchPost('/markets', JSON.stringify(market))

        if (res.ok) {
            navigate('/')
        }
    }

    return (
        <>
            <form onSubmit={handleForm}>
                <Text label="Nome mercado" id="name" placeholder="Ex: Assaí" onChange={(e) => setValue(e.target.value)}/>
                <Button type="submit">Cadastrar</Button>
                <Button onClick={() => { navigate('/')}}>Voltar</Button>
            </form>
        </>
    );
}

export default NovoMercardo;