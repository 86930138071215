const API_BASE_URL = '/api'
const token = localStorage.getItem('token')
const response = {
    body: null,
    status_code: 0,
    ok: false
}

export async function fetchGet(endpoint) {
    
    const res = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        }
    })
    return prepare(res)
        
}

export async function fetchPost(endpoint, body) {
    
    const res = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        }
    })
    return prepare(res)
        
}

export async function fetchDelete(endpoint, body) {
    
    const res = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        }
    })
    return prepare(res, true)
        
}

const prepare = async (res, no_content) => {
    response.body = (no_content) ? null : await res.json();
    response.status_code = res.status;
    response.ok = res.ok;
    return response
}

const getToken = () => {
    checkToken();
    const objToken = localStorage.getItem('token')
    if (objToken) {
        return JSON.parse(objToken).value
    }
}

const checkToken = () => {
    const storagedToken = localStorage.getItem('token')
    if (storagedToken) {
      const obj = JSON.parse(storagedToken)
      if ((new Date()).getTime() > obj.expiry)  {
        localStorage.removeItem('token');
        return false;
      }
    }
}