import React, { useEffect, useState } from 'react';
import './NovaCompra.css';
import { useNavigate } from 'react-router-dom';
import { fetchGet, fetchPost } from '../Api';

function NovaCompra() {

    const [options, setOptions] = useState([]);
    const [market, setMarket] = useState(null);
    const navigate  = useNavigate();


    useEffect (() => {
        const fetchOptions = async () => {
            try {
                const response = await fetchGet('/markets')
                setOptions(response.body);
            } catch (err) {
                setOptions([])
            } finally {
                
            }
        };

        fetchOptions();
    }, []);

    
    const setHandled = (event) => {
        setMarket(event.target.value);
    }

    const handleClick = () => {
        navigate('/')
    }

    const createNewPurchase = async() => {
        try {
            
            let marketId = Number(market);
            const payload = {market_id: marketId}
            const uri = '/api/purchases';
            const token = localStorage.getItem('token')
            
            const response = await fetchPost('/purchases', JSON.stringify(payload));

            if (response.ok) {
                const result = response.body
                navigate('/compra/'+result.id)
            } else {
                alert("Ocorreu um erro")
            }
            
        } catch (error) {

        }
    };

    return (        
        <div className="overflow-x-auto">

        <div className="relative w-full">
        <select value={market} onChange={setHandled} className="block appearance-none w-full p-2 border border-gray-300 rounded-lg bg-white focus:outline-none  focus:border-blue-500 mb-2">
            <option key="1">Selecione</option>
                    {options.map((option) =>(
                        <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
        </div>
        </div>
            <button onClick={createNewPurchase} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-2">Iniciar</button>
            <button onClick={handleClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Voltar</button>
        </div>
    )
}

export default NovaCompra;